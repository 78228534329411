







































































































































































































































































































































































































































































































































































































































































































































































































































































.btn-box {
  padding: 50px 0 10px;
  display: flex;
  justify-content: center;
  button {
    padding: 12px 30px;
  }
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
